<template>
  <rtb-card class="screen-share-intro">
    <template
      v-if="!(isSharing || (sharingScreenOn && sharingAudioOn)) || skip"
    >
      <rtb-card-body>
        <Tips>
          <ul>
            <li>
              Ensure you select the correct browser tab or window you intend to
              share
              <br />
              from the prompt that shows up after clicking the
              <b>SHARE</b> button.
            </li>
          </ul>
        </Tips>
      </rtb-card-body>
      <rtb-card-actions>
        <rtb-button @click="onSetScreenSharing" :disabled="loadingScreenShare">
          Share
        </rtb-button>
        <rtb-button color="dark" @click="$emit('close')"> Cancel </rtb-button>
      </rtb-card-actions>
    </template>
    <template v-else>
      <rtb-card-body>
        <v-layout column>
          <v-flex align-self-center>
            <h3 class="share-info mt-2">
              Are you sure you would like to screen share? This will stop the
              current screen share
            </h3>
          </v-flex>
          <v-layout row align-self-center space-between mt-5>
            <rtb-button @click="skip = true" class="mr-3"> Yes </rtb-button>
            <rtb-button color="dark" @click="$emit('close')"> No </rtb-button>
          </v-layout>
        </v-layout>
      </rtb-card-body>
    </template>
  </rtb-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import {
  RtbCard,
  RtbCardBody,
  RtbCheckbox,
  RtbInlineHelp,
  RtbButton,
  RtbCardActions
} from "@/components/ui"
import Tips from "./Tips"

export default {
  name: "ScreenShareIntro",
  components: {
    RtbCard,
    RtbCardBody,
    RtbCheckbox,
    RtbInlineHelp,
    RtbButton,
    RtbCardActions,
    Tips
  },
  data() {
    return {
      loadingScreenShare: false,
      shareOnlyAudio: false,
      skip: false
    }
  },
  computed: {
    ...mapGetters(["orgID"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("twilio", ["screenshareVideoTrack", "screenshareAudioTrack"]),
    sharingScreenOn() {
      return this.screenshareVideoTrack?.mediaStreamTrack
    },
    sharingAudioOn() {
      return this.screenshareAudioTrack?.mediaStreamTrack
    },
    gameStatus() {
      return this.$store.getters.gameStatus
    },
    shareRef() {
      return this.gameStatus.shareRef
    },
    isSharing() {
      return !!this.shareRef?.screenShareBy
    }
  },
  methods: {
    ...mapActions("ScreenCapture", ["setIsSharingScreen"]),
    onSetScreenSharing() {
      this.loadingScreenShare = true
      this.setIsSharingScreen(true)
      this.$emit("close")
      this.loadingScreenShare = false
    }
  }
}
</script>
<style lang="scss">
.share-info {
  color: #fff;
}
</style>
