var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "rtb-card",
    { staticClass: "screen-share-intro" },
    [
      !(_vm.isSharing || (_vm.sharingScreenOn && _vm.sharingAudioOn)) ||
      _vm.skip
        ? [
            _c(
              "rtb-card-body",
              [
                _c("Tips", [
                  _c("ul", [
                    _c("li", [
                      _vm._v(
                        " Ensure you select the correct browser tab or window you intend to share "
                      ),
                      _c("br"),
                      _vm._v(
                        " from the prompt that shows up after clicking the "
                      ),
                      _c("b", [_vm._v("SHARE")]),
                      _vm._v(" button. "),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "rtb-card-actions",
              [
                _c(
                  "rtb-button",
                  {
                    attrs: { disabled: _vm.loadingScreenShare },
                    on: { click: _vm.onSetScreenSharing },
                  },
                  [_vm._v(" Share ")]
                ),
                _c(
                  "rtb-button",
                  {
                    attrs: { color: "dark" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close")
                      },
                    },
                  },
                  [_vm._v(" Cancel ")]
                ),
              ],
              1
            ),
          ]
        : [
            _c(
              "rtb-card-body",
              [
                _c(
                  "v-layout",
                  { attrs: { column: "" } },
                  [
                    _c("v-flex", { attrs: { "align-self-center": "" } }, [
                      _c("h3", { staticClass: "share-info mt-2" }, [
                        _vm._v(
                          " Are you sure you would like to screen share? This will stop the current screen share "
                        ),
                      ]),
                    ]),
                    _c(
                      "v-layout",
                      {
                        attrs: {
                          row: "",
                          "align-self-center": "",
                          "space-between": "",
                          "mt-5": "",
                        },
                      },
                      [
                        _c(
                          "rtb-button",
                          {
                            staticClass: "mr-3",
                            on: {
                              click: function ($event) {
                                _vm.skip = true
                              },
                            },
                          },
                          [_vm._v(" Yes ")]
                        ),
                        _c(
                          "rtb-button",
                          {
                            attrs: { color: "dark" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("close")
                              },
                            },
                          },
                          [_vm._v(" No ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }